import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDomain'
})
export class RemoveDomainPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return new URL(value).pathname;
  }

}
