import { CoreComponent } from './core.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MenuComponent } from './menu/menu.component';
import { SidewidgetComponent } from './sidewidget/sidewidget.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { ViewsModule } from '../views/views.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CoreComponent,
    SidenavComponent,
    MenuComponent,
    SidewidgetComponent,
    FormModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ViewsModule
  ],
  exports: [
    CoreComponent
  ],
  entryComponents: [
    FormModalComponent
  ]
})
export class CoreModule { }
