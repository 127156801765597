import { NgModule } from '@angular/core';

import {
  MatToolbarModule, MatCardModule, MatButtonModule, MatSidenavModule, MatDialogModule,
  } from '@angular/material';

@NgModule({
  exports: [
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatDialogModule
  ],
  providers: [],
  declarations: [],
})
export class AngularMaterialModule { }
