import { InviewContainerDirective } from './inview-container.directive';
import { Directive, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[appInviewItem]'
})
export class InviewItemDirective implements OnInit {
  @Output() inView = new EventEmitter();
  isInView = false;

  public onInView() {
    this.inView.emit();
    this.isInView = true;
  }

  constructor(
    private el: ElementRef,
    private inViewContainer: InviewContainerDirective,
  ) { }

  public getBoundingClientRect() {
    return this.el.nativeElement.getBoundingClientRect();
  }

  ngOnInit() {
    this.inViewContainer.add(this);
  }
}
