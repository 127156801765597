import { WordpressService } from 'src/app/wordpress.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() sideNavNeeded = true;
  @Input() sideNavOpen = false;
  @Output() sideNavToggle = new EventEmitter();
  $headerImage;

  constructor(private wp: WordpressService) {
  }


  ngOnInit() {
    this.$headerImage = this.wp.getSettings().
      pipe(
        shareReplay(1),
        map( res => res ? res.header_image : false)
      );
  }

  toggleSideNav() {
    this.sideNavToggle.emit();
  }

  scrollToTop( duration) {
    const startingY = window.pageYOffset;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) { start = timestamp; }
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + (-startingY * percent));
      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }
}
