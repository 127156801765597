import {
  Directive,
  HostListener,
  } from '@angular/core';
import { InviewItemDirective } from './inview-item.directive';

@Directive({
  selector: '[appInviewContainer]'
})
export class InviewContainerDirective {
  inViewItems: InviewItemDirective[] = [];
  @HostListener('window:resize')
  @HostListener('window:scroll')
  onScroll() {
    this.inView();
  }

  constructor() {
  }

  inView() {
    if (this.inViewItems.length) {
      requestAnimationFrame(
        () => {
          this.inViewItems.forEach(
            (item) => {
              if (!item.isInView) {
                const cRect = item.getBoundingClientRect();
                if (0 < cRect.top + cRect.height && window.innerHeight > cRect.top) {
                  item.onInView();
                }
              }
            }
          );
        }
      );
    }

  }

  add(item) {
    this.inViewItems.push(item);
    setTimeout(() => {
      this.inView();
    }, 300);
  }
}
