import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-progressive-image',
  templateUrl: './progressive-image.component.html',
  styleUrls: ['./progressive-image.component.scss']
})
export class ProgressiveImageComponent implements OnInit {
  @Input() imgData;
  @Input() titleData;
  @Input() imgExtras;
  full: string;
  thumb: string;
  toUse: string;
  loaded = false;
  finished = false;

  style;
  class;
  @ViewChild('container') container: ElementRef;
  @ViewChild('tiny') tiny: ElementRef;
  constructor(private renderer2: Renderer2) {}

  ngOnInit() {
    /*if (true) {
      this.style = {'object-position': '50% 50%'};
    } */
    if (this.imgData) {
      if(this.imgData.type) {
        // use this when full wordpress imgData is supplied;
        this.thumb = this.imgData.sizes.thumbnail.url;
        this.full = this.imgData.sizes.full.url;
      } else {
        this.thumb = this.imgData.thumbnail;
        this.full = this.imgData.full;
      }

    if (this.imgExtras) {
        const offset = this.imgExtras.offset;
        const offsetStyle =  offset ? {'object-position' : offset.x + '% ' + offset.y + '%'} : undefined;
        const rounded = this.imgExtras.rounded;
        const borderStyle = rounded ? {'border-radius' : rounded + '%'} : undefined;
        const styles = {
          ...offsetStyle,
          ...borderStyle
        }
        this.style = styles ;
      }
    }

  }

  loadFullImage() {
    this.toUse = this.full;
  }

  removeBlurred() {
    this.renderer2.removeChild(this.container.nativeElement, this.tiny.nativeElement);
    this.finished = true;
  }
}
