import { Component, OnInit, Input, AfterViewChecked, HostListener, ViewChild, Renderer2, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-title-block',
  templateUrl: './title-block.component.html',
  styleUrls: ['./title-block.component.scss']
})
export class TitleBlockComponent implements AfterViewChecked {
  @Input() abbreviation;
  @Input() title;
  @Input() parent;
  @Input() onBackground = false;
  @ViewChild( 'abbr' ) abbrEl: ElementRef;
  @ViewChild( 'fulltitle' ) titleEl: ElementRef;

  constructor(
    private render: Renderer2,
  ) { }

  ngAfterViewChecked() {
    this.fontSize();
  }

  fontSize() {
    const parentSize = this.parent.clientHeight;
    if(this.abbreviation[0].length >= 3) {
      this.render.setStyle(this.abbrEl.nativeElement, 'fontSize', parentSize / 5 + 'px');
    } else {
      this.render.setStyle(this.abbrEl.nativeElement, 'fontSize', parentSize / 4 + 'px');
    }
    this.render.setStyle(this.titleEl.nativeElement, 'fontSize', parentSize / 16 + 'px');
  }
}
