import { CategoryState, PostState } from './../../wordpress.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { WordpressService } from 'src/app/wordpress.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, OnDestroy {
  posts$: Observable<CategoryState>;
  postsSub: Subscription;
  stickySub: Subscription;
  categorySlug = 'frontpage';
  loading = true;
  itemsPerPage = 12;
  maxStickyItems = 5;
  isotopeOptions = {
    itemSelector: '.grid-item',
    percentPosition: true,
    layoutMode: 'packery',
    packery: {
    }
  };

  slideConfigFade = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    infinite: true,
    fade: true,
    cssEase: 'linear',
    pauseOnHover: false
  };

  constructor(
    private wp: WordpressService,
    private route: ActivatedRoute
    ) {
  }

  ngOnInit() {
    const category = this.route.snapshot.paramMap.get('category');
    const childCategory = this.route.snapshot.paramMap.get('child-category');
    if ( childCategory ) {
      this.categorySlug = childCategory;
    } else if ( category ) {
      this.categorySlug = category;
    }
    this.wp.initCategory(
      this.categorySlug,
      [
       { per_page: this.maxStickyItems, sticky: true },
       { per_page: this.itemsPerPage, sticky: false }
      ]
    );
    this.posts$ = this.wp.posts.pipe(
      shareReplay(1)
    );
    this.postsSub = this.posts$.subscribe((res) => {
      if (res.nonStickies.posts.length > 0) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.postsSub.unsubscribe();
  }

  loadMore() {
    this.loading = true;
    this.wp.getPosts(this.categorySlug, this.itemsPerPage, false);
  }

}
