import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent implements OnInit {
  @Input() innerBlocks;
  innerHTML = '';
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    console.log(this.innerBlocks);
    for (const block of this.innerBlocks ) {
      if(block.innerHTML) {
        this.innerHTML += block.innerHTML;
      }
    }
    this.el.nativeElement.innerHTML = this.innerHTML;
  }

}
