import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  constructor(
    private sideNav: MatSidenav,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe(
      e => {
        if (e instanceof NavigationEnd) {
          this.sideNav.close();
        }
      }
    );
  }
}
