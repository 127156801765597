import { Component, OnInit, Input } from '@angular/core';
import { ControlPosition, ZoomControlStyle } from '@agm/core/services/google-maps-types';

@Component({
  selector: 'app-google-maps-block',
  templateUrl: './google-maps-block.component.html',
  styleUrls: ['./google-maps-block.component.scss']
})
export class GoogleMapsBlockComponent implements OnInit {
  @Input() mapConfig;
  defaultConfig = {
    lat: 52.326118,
    lng: 9.712317,
    label: '',
    marker: {
      url: 'http://svgur.com/i/209.svg',
      scaledSize: {
        height: 50,
        width: 50
      },
      anchor: {
        x: 25,
        y: 50
      }
    }
  };
  mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#414141"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#777777"
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
    this.mapConfig = {
      ...this.defaultConfig,
      ...this.mapConfig,
    };
  }

  // add to selector (mapReady)="onMapReady($event)"
  onMapReady(map) {
    map.setOptions({
      zoomControlOptions: {
        position: ControlPosition.TOP_LEFT,
      }
    });
  }
}
