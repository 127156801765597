import { WordpressService } from './../../wordpress.service';
import { Observable } from 'rxjs';
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit {
  pageContent$: Observable<any>;
  content;
  constructor(
    private wp: WordpressService,
    public dialogRef: MatDialogRef<FormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.pageContent$ = this.wp.getSingle(this.data.url).pipe(
      shareReplay(1)
    );
    this.pageContent$.subscribe(
      res => this.content = res.post_content_filtered
    );
  }

/*   onClick() {
    const element = document.getElementsByClassName('wpcf7-form')[0];
    this.dialogRef.close();
    (element as HTMLFormElement).submit();
  } */

}
