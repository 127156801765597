import { Component, OnInit, ElementRef, Input, OnChanges } from '@angular/core';

declare var Isotope: any;
declare var imagesLoaded: any;

@Component({
  selector: 'app-isotope-grid',
  templateUrl: './isotope-grid.component.html',
  styleUrls: ['./isotope-grid.component.scss']
})
export class IsotopeGridComponent implements OnInit, OnChanges {

  @Input() public options;

  private items: Array<HTMLElement> = [];
  private isotope: any;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    if (!this.options) {
      this.options = {
        itemSelector: '.grid-item',
      };
    }

     if (this.el.nativeElement.tagName === 'APP-ISOTOPE-GRID') {
      this.el.nativeElement.style.display = 'block';
    }

    this.isotope = new Isotope(this.el.nativeElement, this.options);
  }

  ngOnChanges(changes): void {
    if (this.isotope && changes.options.currentValue) {
      this.isotope.arrange(changes.options.currentValue);
    }
  }

  public layout() {
    this.isotope.reloadItems();
  }

  public add(el: HTMLElement) {
    this.isotope.appended(el);
    this.isotope.layout();
  }
}
