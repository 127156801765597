import { FlexLayoutModule } from '@angular/flex-layout';
import { ProgressiveImageComponent } from './progressive-image/progressive-image.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { InviewContainerDirective } from './inview-container.directive';
import { InviewItemDirective } from './inview-item.directive';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { IsotopeGridComponent } from './isotope/isotope-grid/isotope-grid.component';
import { RouterModule } from '@angular/router';
import { IsotopeItemDirective } from './isotope/isotope-grid/isotope-item.directive';
import { RemoveDomainPipe } from './remove-domain.pipe';
import { SafeContentPipe } from './safe-content.pipe';
import { ScrollFadeDirective } from './scroll-fade.directive';

@NgModule({
  declarations: [
    InviewContainerDirective,
    InviewItemDirective,
    IsotopeGridComponent,
    IsotopeItemDirective,
    ProgressiveImageComponent,
    RemoveDomainPipe,
    SafeContentPipe,
    ScrollFadeDirective
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    SlickCarouselModule,
  ],
  exports: [
    RouterModule,
    FlexLayoutModule,
    AngularMaterialModule,
    SlickCarouselModule,
    InviewContainerDirective,
    InviewItemDirective,
    IsotopeGridComponent,
    IsotopeItemDirective,
    ProgressiveImageComponent,
    RemoveDomainPipe,
    SafeContentPipe,
    ScrollFadeDirective,
  ]
})
export class SharedModule { }
