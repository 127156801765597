import { Observable } from 'rxjs';
import { WordpressService } from 'src/app/wordpress.service';
import { Component, OnInit } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  $settings: Observable<any>
  showOnFront;
  pageOnFront;
  constructor(
    private wp: WordpressService
  ) { }

  ngOnInit() {
    this.$settings = this.wp.getSettings().
    pipe(
      shareReplay(1)
    );

    this.$settings.subscribe(
      res => {
        if (res !== null) {
          this.showOnFront = res.show_on_front;
          if (res.page_on_front) {
            this.pageOnFront = res.page_on_front.gutenberg_blocks;
          }
        }
      }
    );
  }

}
