import { CategoryState } from './../../../wordpress.service';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WordpressService } from 'src/app/wordpress.service';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-grid-block',
  templateUrl: './grid-block.component.html',
  styleUrls: ['./grid-block.component.scss']
})
export class GridBlockComponent implements OnInit, OnDestroy {

  @Input() categorySlug;
  @Input() innerBlocks;

  posts$: Observable<CategoryState>;
  postsSub: Subscription;
  loading = true;
  itemsPerPage = 12;
  maxStickyItems = 5;
  isotopeOptions = {
    itemSelector: '.grid-item',
    percentPosition: true,
    layoutMode: 'packery',
    packery: {
    }
  };

  constructor(
    private wp: WordpressService,
    ) {
  }

  ngOnInit() {
    if(this.categorySlug) {
      this.wp.initCategory(
        this.categorySlug,
        [
         { per_page: this.maxStickyItems, sticky: true },
         { per_page: this.itemsPerPage, sticky: false }
        ]
      );
      this.posts$ = this.wp.posts.pipe(
        shareReplay(1)
      );
      this.postsSub = this.posts$.subscribe((res) => {
        if (res.nonStickies.posts.length > 0) {
          this.loading = false;
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.postsSub) {
      this.postsSub.unsubscribe();
    }
  }

  loadMore() {
    this.loading = true;
    this.wp.getPosts(this.categorySlug, this.itemsPerPage, false);
  }

  calcSize(el, factor) {
    setTimeout(() => {
      return {'font-size':  el.clientHeight * factor + 'px'};
    });
  }


}
