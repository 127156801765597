import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WordpressService } from 'src/app/wordpress.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { FormModalComponent } from '../form-modal/form-modal.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menuData$: Observable<any[]>;
  @Input() verticalMenu = false;
  @Input() useIcons = false;
  @Input() customClass = '';
  @Input() showInfoButton = true;
  @Input() slug = 'ng_primary';
  scrollOffset;

  constructor(
    private wp: WordpressService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.menuData$ = this.wp.getMenu(this.slug).pipe(
      shareReplay(1)
    );
/*     this.menuData$.subscribe(
      res => console.log(res)
    ); */
  }

  scrollToItem(id, duration) {
    const el: HTMLElement = (document.getElementById(id));
    const elementY = Math.floor(el.getBoundingClientRect().bottom - window.innerHeight);
    const startingY = window.pageYOffset;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) { start = timestamp; }
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + (elementY * percent));
      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

  onClick(url) {
    const dialogRef = this.dialog.open(FormModalComponent, {
      width: '60vw',
      data: {url: url}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
    });
  }

}
