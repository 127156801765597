import { WordpressService } from 'src/app/wordpress.service';
import { Component, OnInit } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
  postList$;

  isotopeOptions = {
    itemSelector: '.grid-item',
    percentPosition: true,
    layoutMode: 'packery',
    packery: {
    }
  };

  constructor(
    private wp:  WordpressService
  ) { }

  ngOnInit() {
    this.postList$ = this.wp.archive.pipe(
      shareReplay(1)
    );

    this.postList$.subscribe(
      (res: any[]) => {
        if ( res.length <= 0 ) {
          this.wp.getArchive('service');
        }
      }
    );
  }
}
