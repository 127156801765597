import { WordpressService } from './wordpress.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingleResolverService implements Resolve<any> {

  constructor(
    private wp: WordpressService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.wp.getSingle(route.params.slug).pipe(
      catchError((err: any) => {
        // console.log(err);
        this.router.navigate(['error']);
        return of(null);
      })
    );
  }
}
