import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  @Input() post;
  postContent;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.post.custom_fields['hb2-grid-size']) {
      this.setClass(this.post.custom_fields['hb2-grid-size']);
    } else {
      this.renderer.addClass(this.el.nativeElement, 'grid-item--standard');
    }
  }

  // adds a classes if width or height should be double or n-times of grid-size
  setClass(gridSize: Array<string>) {
    if (gridSize.length > 0) {
      const gridParams = gridSize[0].split('x');
      if (gridParams[0] !== '1' ) {
        this.renderer.addClass(this.el.nativeElement, 'grid-item--width' + gridParams[0]);
      }
      if (gridParams[1] !== '1' ) {
        this.renderer.addClass(this.el.nativeElement, 'grid-item--height' + gridParams[1]);
      }
    }
  }

  returnUnsanitized(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
