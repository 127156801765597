import { Directive, Renderer2, OnInit, HostListener, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appScrollFade]'
})
export class ScrollFadeDirective implements OnInit {
  @Input('appScrollFade') className;
  @Input() scrollAmount = 100;
  @HostListener('window:resize')
  @HostListener('window:scroll')
  onScroll() {
    this.checkIfTop();
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.className = this.className ? this.className : 'scroll-show';
    this.checkIfTop();
  }

  checkIfTop() {
    if( window.scrollY > this.scrollAmount) {
      this.renderer.addClass(this.el.nativeElement, this.className);
    } else {
      this.renderer.removeClass(this.el.nativeElement, this.className);
    }
  }

}
