import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-slider-block',
  templateUrl: './slider-block.component.html',
  styleUrls: ['./slider-block.component.scss']
})
export class SliderBlockComponent implements OnInit {
  @Input() innerBlocks;

  slideConfigFade = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    infinite: true,
    fade: true,
    cssEase: 'linear',
    pauseOnHover: false
  };
  constructor() { }

  ngOnInit() {
  }

}
