import { WordpressService } from './../wordpress.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-core',
  templateUrl: 'core.component.html',
  styleUrls: ['./core.component.scss']
})

export class CoreComponent implements OnInit {
  @ViewChild('sidenav') sideNav: MatSidenav;
  sideNavNeeded = true;
  sideWidget = false;
  menu$: Observable<any[]>;

  constructor (
    private breakpointObserver: BreakpointObserver,
    private wp: WordpressService
    ) {
    this.breakpointObserver.observe([
      '(min-width: 960px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.sideWidget = true;
        this.sideNavNeeded = false;
        this.sideNav.close();
      } else {
        this.sideWidget = false;
        this.sideNavNeeded = true;
      }
    });
  }

  ngOnInit() {
    this.wp.getMenus();
    this.wp.initSettings();
  }
}
