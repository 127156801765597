import { DomSanitizer } from '@angular/platform-browser';
import { WordpressService } from './../../wordpress.service';
import { Component, OnInit } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  $settings;
  footerPage;

  constructor(
    private wp: WordpressService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.$settings = this.wp.getSettings().
    pipe(
      shareReplay(1)
    );

    this.$settings.subscribe(
      res => {
        if (res !== null) {
          this.footerPage = res.footer_page.gutenberg_blocks;
          // this.footerPage = this.returnUnsanitized(res.footer_page.post_content);
        }
      }
    );
  }

  returnUnsanitized(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
