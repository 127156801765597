import { CustomPostComponent } from './../custom-post/custom-post.component';
import { PageComponent } from './../page/page.component';
import { PostSingleComponent } from './../post-single/post-single.component';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss']
})
export class SingleComponent implements OnInit {

  component;
  constructor(public route: ActivatedRoute) { }

  ngOnInit() {
    // Observe data changes since there will be routes between Pages so the PageComponent is already initialized, but the route data changes
    this.route.data.subscribe(
      (res) => {
        switch (res.message.post_type) {
          case 'service':
              this.component = CustomPostComponent;
            break;
          case 'page': {
              this.component = PageComponent;
            break;
          }
          default: {
              this.component = PostSingleComponent;
            break;
          }
        }
      }
    );
  }

}
