import { CategoryState } from './wordpress.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';

export interface PostState {
  posts: any[];
  fetchedAll: boolean;
}

export interface CategoryState {
  all?: PostState;
  stickies?: PostState;
  nonStickies?: PostState;
}

const RESTAPI = 'https://www.homebase2.com/wordpress/wp-content/plugins/decoupled-json-content/rest/';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  currentCategory: string;

  postStoreInitialState: CategoryState = {
    all: {posts: [], fetchedAll: false},
    stickies: {posts: [], fetchedAll: false},
    nonStickies: {posts: [], fetchedAll: false}
  };

  private postStore = new Map<string, CategoryState>();

  private _categories = new BehaviorSubject<any[]>([]);
  public categories = this._categories.asObservable();

  private _archive = new BehaviorSubject<any[]>([]);
  public archive = this._archive.asObservable();

  private _posts = new BehaviorSubject<CategoryState>(this.postStoreInitialState);
  public posts = this._posts.asObservable();

  private _settings = new BehaviorSubject<any>(null);

  private _menus = new BehaviorSubject<any[]>(null);

  constructor (
    private http: HttpClient
  ) {}

  initCategory(category, initsParams: { per_page: number, sticky?: boolean}[]) {
    this._posts.next(this.postStoreInitialState);
    if (category !== this.currentCategory) {
      const categoryState = this.postStore.get(category);
      if (categoryState) {
        this._posts.next(categoryState);
      } else {
        for (const param of initsParams) {
          const params = {
            category: category,
            ...param
          };
          this.getPosts(...Object.values(params));
        }
      }
    }
  }

  getPosts(category = 'frontpage', per_page = 12, sticky?: boolean) {
      const currentState = this._posts.getValue();
      let currentPosts;
      let additionalParams = {};
      switch (sticky) {
        case undefined:
          currentPosts = currentState.all;
        break;
        case true:
          currentPosts = currentState.stickies;
          additionalParams = { sticky: 1};
          break;
        case false:
          currentPosts = currentState.nonStickies;
          additionalParams = { sticky: 0};
        break;
      }
      let nextPage = Math.floor(currentPosts.posts.length / per_page);
      nextPage++;
      this.http.get<{total_pages: number, posts: any[]}>(RESTAPI + 'query.php?_embed', {
        params: {
          category_name: category,
          per_page: per_page.toString(),
          page: nextPage.toString(),
          ...additionalParams
        }
      }).subscribe(
        res => {
          const totalPages = res.total_pages;
          const posts =  [...currentPosts.posts, ...res.posts];
          const fetchedAll = Math.ceil(posts.length / per_page) >= totalPages;
          let newData;
          switch (sticky) {
            case undefined:
              newData = {all: {posts: posts, fetchedAll: fetchedAll}};
              break;
              case true:
              newData = {stickies: {posts: posts, fetchedAll: fetchedAll}};
              break;
              case false:
              newData = {nonStickies: {posts: posts, fetchedAll: fetchedAll}};
            break;
          }
          const newState = {
            ...this._posts.getValue(),
            ...newData
          };
          this.postStore.set(category, newState);
          this._posts.next(newState);
      });
  }

  getSingle(slug: string) {
    return this.http.get<any[]>(RESTAPI + 'page.php', {
      params: {
        slug: slug
      }
    });
  }

  // MEDIA
  getImagesByIds(ids: any[]) {
    return this.http.get<{total_pages: number, attachments: any[]}>(RESTAPI + 'media.php', {
      params: {
        include: ids.join(','),
        orderby: 'post__in'
      }
    });
  }

  // CATEGORIES

  getCategories(slug = 'frontpage' ) {
    this.http.get<any[]>( RESTAPI + 'category.php')
    .subscribe(
      res => {
        const parentId = res.find((item) => item.slug === slug).term_id;
        const filteredCategories = res.filter((item) => item.parent === parentId);
        this._categories.next(filteredCategories);
      });
  }

  getArchive(postType = 'post', filter = 'default' ) {
    this.http.get<any[]>( RESTAPI + 'data-list.php', {
      params: {
        'post-type': postType,
        'filter': filter
      }
    })
    .subscribe(
      res => {
        this._archive.next(res);
      });
  }

  // CUSTOMIZER
  // Handling Settings and Custom Settings
  initSettings() {
    this.http.get<any>( RESTAPI + 'settings.php').subscribe(
      (res) => {
        this._settings.next(res);
      }
    );
  }

  getSettings(): Observable<any> {
    return this._settings.asObservable();
  }



  // MENUS
  // Handling Menus
  getMenus() {
    this.http.get<any[]>( RESTAPI + 'menu.php').subscribe((res) => {
      this._menus.next(res);
    });
  }

   getMenu(slug): Observable<any> {
    return this._menus.asObservable().pipe(
      map(
        res => {
          if (!res) {
            return null;
          } else {
            return res.find((item) => item.position === slug);
          }
        }
      ),
      map(res => {
        if (!res) {
          return null;
        }
        return this.menuMap(res);
      })
    );
  }



  menuMap(menu) {
    const items = [];
    menu.items.forEach(element => {
    items.push(
        {
          id: element.id,
          title: element.title,
          slug: element.slug,
          menu_item_parent: element.parent,
          url: element.url,
          classes: element.classes
        }
        );
      });
    const parents = items.filter(item => !item.menu_item_parent);
    parents.forEach((parent) => {
      const id = parent.id.toString();
      const children = items.filter(child => child.menu_item_parent === id);
      parent.children = children;
    });
    return parents;
  }
}
