import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appBlock]'
})
export class BlockDirective implements OnInit {
  @Input('appBlock') content;
  @Input() isWrapper = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.content = this.content.replace(/<br>/gi, '<br />'); // non sel-closing breaks will cause an error;
    const doc = new DOMParser().parseFromString(this.content, 'text/xml');
    const wrappingEl = doc.firstElementChild;
    // set attributes of the host Element
    if (wrappingEl.hasAttributes) {
      const attrs = wrappingEl.attributes;
      for(let i = attrs.length - 1; i >= 0; i--) {
        if(attrs[i].name === 'class') {
          const classes =  attrs[i].value.split(' ');
          for (const current of classes) {
            this.renderer.addClass(this.el.nativeElement, current);
          }
        } else {
          this.renderer.setProperty(this.el.nativeElement, attrs[i].name, attrs[i].value);
        }
      }
    }
    // set the innerHTML of the host Element
    let innerHTML = wrappingEl.innerHTML;
    if (!this.isWrapper){
      this.el.nativeElement.innerHTML = innerHTML;
    }
  }

}
