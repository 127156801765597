import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[appBlockContent]',
  templateUrl: './block-content.component.html',
  styleUrls: ['./block-content.component.scss']
})
export class BlockContentComponent implements OnInit {
  @Input('appBlockContent') appBlockContent;
  constructor() { }

  ngOnInit() {
  }

/*   getTemplate(blockName) {
    console.log(blockName);
    switch (blockName) {
      case 'hb2/grid-block':
      case 'hb2/maps-block':
      case 'core/paragraph':
      case 'core/image':
      case 'core/html':
      default:
      return 'default';
    }
  } */

}
