import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { ArchiveComponent } from './views/archive/archive.component';
import { ErrorComponent } from './views/error/error.component';
import { CategoryComponent } from './views/category/category.component';
import { SingleResolverService } from './single-resolver.service';
import { SingleComponent } from './views/single/single.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryListComponent } from './views/category-list/category-list.component';
import { RetainScrollPolyfillModule } from './retain-scroll-polyfill/retain-scroll-polyfill.module';

const routes: Routes = [
  {
    path: '', component: LandingPageComponent,
    pathMatch: 'full'
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'services',
    component: ArchiveComponent,
  },
  {
    path: 'categories',
    component: CategoryListComponent,
  },
  {
    path: 'category/:category',
    component: CategoryComponent,
  },
  {
    path: 'category/:category/:child-category',
    component: CategoryComponent,
  },
  {
    path: ':slug',
    component: SingleComponent,
    resolve: {
      message: SingleResolverService
    }
  },
  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [
    RetainScrollPolyfillModule.forRoot({
      // Tell the polyfill how long to poll the document after a route change in
      // order to look for elements that need to be restored to a previous offset.
      pollDuration: 3000,
      pollCadence: 50
    }),
    RouterModule.forRoot(routes,
    {
      // Tell the router to use the hash instead of HTML5 pushstate.
      // useHash: true,

      // For the demonstration of this polyfill, I am disabling the native
      // scroll retention and restoration behaviors of Angular 6+.
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'disabled',
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
