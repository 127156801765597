import { WordpressService } from 'src/app/wordpress.service';
import { Component, OnInit } from '@angular/core';
import { WritePropExpr } from '@angular/compiler';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  categories$;

  isotopeOptions = {
    itemSelector: '.grid-item',
    percentPosition: true,
    layoutMode: 'packery',
    packery: {
    }
  };

  constructor(
    private wp:  WordpressService
  ) { }

  ngOnInit() {
    this.categories$ = this.wp.categories.pipe(
      shareReplay(1)
    );

    this.categories$.subscribe(
      (res: any[]) => {
        if ( res.length <= 0 ) {
          this.wp.getCategories('dienstleistungen');
        }
      }
    );
  }
}
