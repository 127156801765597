import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-post',
  templateUrl: './custom-post.component.html',
  styleUrls: ['./custom-post.component.scss']
})
export class CustomPostComponent implements OnInit {
  itemData;
  pageContent;
  data;
  constructor(
    private route: ActivatedRoute,
      ) {}
  ngOnInit() {
    // Observe data changes since there will be routes between Pages so the PageComponent is already initialized, but the route data changes
    this.route.data.subscribe(
      res => {
        console.log(res);
        this.itemData = res.message;
      }
    );
  }
}
