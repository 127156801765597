import { AgmCoreModule } from '@agm/core';
import { PostComponent } from './post/post.component';
import { SharedModule } from './../shared/shared.module';
import { SingleComponent } from './single/single.component';
import { PageComponent } from './page/page.component';
import { CategoryComponent } from './category/category.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryListComponent } from './category-list/category-list.component';
import { PostSingleComponent } from './post-single/post-single.component';
import { ErrorComponent } from './error/error.component';
import { ArchiveComponent } from './archive/archive.component';
import { GridBlockComponent } from './blocks/grid-block/grid-block.component';
import { CustomPostComponent } from './custom-post/custom-post.component';
import { TitleBlockComponent } from './blocks/title-block/title-block.component';
import { BlockDirective } from './blocks/block.directive';
import { TextBlockComponent } from './blocks/text-block/text-block.component';
import { GoogleMapsBlockComponent } from './blocks/google-maps-block/google-maps-block.component';
import { BlockContentComponent } from './blocks/block-content/block-content.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SliderBlockComponent } from './blocks/slider-block/slider-block.component';

@NgModule({
  declarations: [
    ArchiveComponent,
    CategoryComponent,
    SingleComponent,
    PostSingleComponent,
    PageComponent,
    CategoryListComponent,
    PostComponent,
    ErrorComponent,
    GridBlockComponent,
    CustomPostComponent,
    TitleBlockComponent,
    BlockDirective,
    TextBlockComponent,
    GoogleMapsBlockComponent,
    BlockContentComponent,
    LandingPageComponent,
    SliderBlockComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAknUMf25PBQLM_iIqj9oR7jeklB80pZjA'
    })
  ],
  exports: [
    CategoryComponent,
    SingleComponent,
    PostSingleComponent,
    PageComponent,
    GoogleMapsBlockComponent,
    BlockContentComponent
  ],
  entryComponents: [
    PostSingleComponent,
    CustomPostComponent,
    PageComponent
  ]
})
export class ViewsModule { }
