import { DomSanitizer } from '@angular/platform-browser';
import { WordpressService } from 'src/app/wordpress.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-post-single',
  templateUrl: './post-single.component.html',
  styleUrls: ['./post-single.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class PostSingleComponent implements OnInit {
  @Input() itemData;
  @ViewChild('content', {read: ViewContainerRef}) contentContainer;
  imageIds = [];
  images = [];
  content = null;
  findImages = new RegExp('wp-image-' + '\\s*(\\d+)', 'g');
  slideConfig = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1000,
    infinite: true,
    pauseOnHover: false
  };

  constructor(
    private wp: WordpressService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
    ) {
  }

  ngOnInit() {
    this.itemData = this.route.snapshot.data.message;
    const content: string = this.itemData.post_content_filtered;
    let m;
    switch (this.itemData.format) {
      case 'gallery':
      case 'image':
      do {
        m = this.findImages.exec(content);
        if (m) {
          this.imageIds.push(m[1]);
        }
      } while (m);
      this.wp.getImagesByIds(this.imageIds).subscribe(
        (res) => {
          this.images = res.attachments;
        }
      );
      break;
      case 'video':
      default:
        this.content = this.returnUnsanitized(content);
    }
  }
  returnUnsanitized(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

/*   parseData(blockData) {
    let output = [];
    for (const block of blockData) {
      if (block.innerBlocks.length > 0) {
        output.push(block.innerContent[0]);
        output = output.concat(this.parseData(block.innerBlocks));
        output.push(block.innerContent[block.innerContent.length - 1]);
      } else {
        if (block.blockName) {
          output.push(block.innerHTML);
        }
      }
    }
    return output.join('');
  } */
}
