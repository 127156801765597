import { ActivatedRoute } from '@angular/router';
import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  itemData;
  pageContent;
  data;
  isClassic = false;
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
      ) {}
  ngOnInit() {
    // Observe data changes since there will be routes between Pages so the PageComponent is already initialized, but the route data changes
    this.route.data.subscribe(
      res => {
        this.itemData = res.message;
        if(this.itemData.gutenberg_blocks.length === 1) {
          if (this.itemData.gutenberg_blocks[0].blockName === null) {
            this.isClassic = true;
          }
        }
        // TODO: Make sure this doesn't compromise Security
        this.pageContent = this.sanitizer.bypassSecurityTrustHtml(this.itemData.post_content_filtered);
      }
    );
  }
}
