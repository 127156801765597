import { IsotopeGridComponent } from './isotope-grid.component';
import { Directive, OnInit, AfterViewInit, ElementRef,  } from '@angular/core';

@Directive({
  selector: '[appIsotopeItem]'
})
export class IsotopeItemDirective implements OnInit, AfterViewInit {

  constructor(
    private grid: IsotopeGridComponent,
    private el: ElementRef) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
      this.grid.add(this.el.nativeElement);
  }

}
